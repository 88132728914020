import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { API_URL } from '../../Constants'

const initialState = {
    category: null,
    companies: [{ company: 'Loading ...' }],
    company: null, // or: 'loading', 'succeeded', 'failed'
    error: null,
    products: [{ product: 'Loading ...' }],
    showCategoryFilter: false,
    showProductFilter: false,
    status: 'idle',
    updated: false,
    year: '2020'
}

export const fetchCompaniesAutocomplete = createAsyncThunk('filters/fetchCompaniesAutocomplete', async (options) => {
    const { filters, pagination } = options
    // pull from api
    // console.log('pagination', pagination);
  const fetchResult = await axios.get(
      `${API_URL}/cache`, {
        // params: { pagination, sb_id }
        params: { key: 'companies' }
    })
    const {data} = fetchResult
    const {companies} = data
    return { ...initialState, companies, status: 'succeded' }
})

export const fetchProductForCompaniesAutocomplete = createAsyncThunk('filters/fetchProductForCompaniesAutocomplete', async (options) => {
  console.log('fetchProductForCompaniesAutocomplete')
    const { filters, pagination } = options
    const { companies, company, year } = filters
    // pull from api
    if (company) {
        // console.log('filters.company', filters.company);
      const fetchResult = await axios.get(
          `${API_URL}/physician/companies/products`, {
            // params: { pagination, sb_id }
            params: { filters: { company, year }, pagination }
        })
      const { data } = fetchResult
      console.log('fetchResult', fetchResult)
      const { products } = data
      console.log('fetchResult products', products)
        return { ...initialState, companies, company, products, showProductFilter: true, status: 'succeded', year }
    }

})

export const filtersYearChanged = createAsyncThunk('filters/filtersYearChanged', async (options) => {
    const { year } = options
    return { status: 'succeeded', updated: true, year }
})

export const filtersCompanyChanged = createAsyncThunk('filters/filtersCompanyChanged', async (options) => {
    // console.log('filtersCompanyChanged options', options)
    const { companies, company, year } = options
    return { ...initialState, companies, company, showProductFilter: company ? true : false, status: 'succeeded', updated: true, year }
})

export const filtersProductChanged = createAsyncThunk('filters/filtersProductChanged', async (options) => {
    // console.log('filtersProductChanged options', options)
    const { companies, company, product, products, year } = options
    return { companies, company, product, products, showCategoryFilter: product ? true : false, showProductFilter: company ? true : false, status: 'succeeded', updated: true, year }
})

export const filtersCategoryChanged = createAsyncThunk('filters/filtersCategoryChanged', async (options) => {
    const { category, companies, company, product, products, year } = options
    return { category, companies, company, product, products, showCategoryFilter: product ? true : false, showProductFilter: true, status: 'succeeded', updated: true, year }
})

export const filtersSlice = createSlice({
    extraReducers: {
        [fetchCompaniesAutocomplete.fulfilled]: (state, action) => {
            return action.payload
        },
        [fetchProductForCompaniesAutocomplete.fulfilled]: (state, action) => {
            return action.payload
        },
        [filtersProductChanged.fulfilled]: (state, action) => {
            return action.payload
        },
        [filtersYearChanged.fulfilled]: (state, action) => {
            return action.payload
        },
        [filtersCompanyChanged.fulfilled]: (state, action) => {
            return action.payload
        },
        [filtersCategoryChanged.fulfilled]: (state, action) => {
            return action.payload
        }
    },
    initialState,
    name: 'filters', reducers: {
        categoryInputValueChanged: (state, action) => {
            return { ...state, categoryInputValue: action.payload }
        },
        companyInputValueChanged: (state, action) => {
            // console.log('company input  change action', action)
            return { ...state, companyInputValue: action.payload }
        },
        updateFilters: (state, action) => {
            // console.log('filters action', action)
            const {payload} = action
            const rv = { ...state, updated: payload }
            // console.log('filters state rv', rv)
            return rv
        }
    }
})

export const { categoryInputValueChanged, companyInputValueChanged, updateFilters } = filtersSlice.actions

export default filtersSlice.reducer
