import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { API_URL } from '../../Constants'

const initialState = {
    error: null,
    first: '',
    last: '',
    limit: 10,
    list: [],
    page: 0,
    status: 'idle',
    total: 0
}
export const fetchSpeakersWithFilters = createAsyncThunk('speakers/fetchSpeakersWithFilters', async (options) => {
    const { filters, includePayments, pagination } = options
    // console.log('FetchSpeakers with filters', options)
    // pull from api
    // console.log('pagination', pagination);
    const fetchFilters = { ...filters }
    if (fetchFilters.companies) {
        delete fetchFilters.companies
    }
    // console.log('fetchFilters', fetchFilters);
    // console.log('fetchFilters includePayments', includePayments)
  const fetchResult = await axios.get(
    `${API_URL}/physician`, {
        // params: { pagination, sb_id }
        params: { filters: fetchFilters, includePayments, pagination }
    })
    const {data} = fetchResult
    const total = data.total ? data.total : 1
    // console.log('fetchFilters data', data)
    let first = ''
    let last = ''
    let list
    let limit
    if (data.result.length > 0) {
        first = data.result[0]._id
        last = data.result.length > 1 ? data.result[data.result.length - 1]._id : data.result[0]._id
        list = data.result.map((obj) => ({ ...obj, show: false }))
        limit = data.result.length
    } else {
        first = data.result._id
        last = data.result._id
        list = [data.result]
        limit = 1
    }

    const speakers = {
        error: null,
        first,
        last,
        limit,
        list,
        page: pagination.page,
        pagination,
        status: 'succeeded',
        total
    }
    return speakers
})

export const speakersSlice = createSlice({
    extraReducers: {
        [fetchSpeakersWithFilters.fulfilled]: (state, action) => {
            return action.payload
        }
    },
    initialState,
    name: 'speakers', reducers: {
      hideAllSpeakersOnMap: (state, action) => {
        const list = state.list.map((obj) => ({ ...obj, show: false }))
        return { ...state, list}

      },
      showSpeakerOnMap: (state , action) => {
        const { sb_id } = action.payload
        const index = state.list.findIndex((speaker) => speaker.sb_id === sb_id)
        const { show } = state.list[index]
        const list = state.list.map((obj) => ({ ...obj, show: false }))
        list[index].show = !show
        return { ...state, list}
      },
        speakerLoaded: (state, action) => {
            state.speakers.push(action.payload)
        },
      speakersLoaded: (state, action) => {
            state.speakers.push(action.payload)
      }
    }
})

export const { hideAllSpeakersOnMap, showSpeakerOnMap, speakerLoaded, speakersLoaded } = speakersSlice.actions

export default speakersSlice.reducer
