import { Client } from '@googlemaps/google-maps-services-js'
import { findPlaceFromText } from '@googlemaps/google-maps-services-js/dist/places/findplacefromtext'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import getSpeakerLocationPOIQuery from '../../../shared/functions/getSpeakerLocationPOIQuery'
import { API_URL } from '../../Constants'

const initialState = {
    error: null,
    locationName: 'N/A',
  physician: {},
    status: 'idle'
}

export const fetchSpeakerBySbId = createAsyncThunk('speakers/fetchSpeakerBySbId', async (options) => {
    const { physicianId } = options
    const fetchResult = await axios.get(
        `${API_URL}/physician`, {
        params: { sb_id: physicianId }
    })
    // console.log('result:', fetchResult)
    const {data} = fetchResult
    const {total} = data
    if (total === 1) {
        const speaker = {
            error: null,
          locationName: null,
            physician: data.result,
            status: 'succeeded'
        }
        return speaker
    }

})

export const fetchSpeakerPOIByAddress = createAsyncThunk('speakers/fetchSpeakerPOIByAddress', async (options) => {
  const { physician } = options
  const address = getSpeakerLocationPOIQuery(physician)
  const fetchResult = await axios.get(
        `${API_URL}/placefromaddress`, {
        params: { address }
  })
  const { data } = fetchResult
  if (data.results.length > 0) {
      return {
          error: null,
        locationName: data.results[0].name,
          physician,
          status: 'succeeded'
      }
  }
    return {
          error: null,
      locationName: null,
          physician,
          status: 'succeeded'
      }
})

export const speakerSlice = createSlice({
  extraReducers: {
    [fetchSpeakerBySbId.fulfilled]: (state, action) => {
      return action.payload
    },
    [fetchSpeakerPOIByAddress.fulfilled]: (state, action) => {
      return action.payload
    }
    },
    initialState,
    name: 'speaker', reducers: {
        speakerLoaded: (state, action) => {
            state.speaker = action.payload
        }
    }
})

export const { speakerLoaded } = speakerSlice.actions

export default speakerSlice.reducer
