import { configureStore } from '@reduxjs/toolkit'

import filtersReducer from './features/filters/filtersSlice'
import speakerReducer from './features/speakers/speakerSlice'
import speakersReducer from './features/speakers/speakersSlice'

export default configureStore({
    reducer: {
        filters: filtersReducer,
        speaker: speakerReducer,
        speakers: speakersReducer
    }
})
