import { withStyles } from '@material-ui/core'
// import { red } from '@material-ui/core/colors'

const styles = (theme) => ({
  '@global': {
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    '*:focus': {
      outline: 0
    },
    '.container': {
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 540
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 720
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1170
      }
    },
    '.container-fluid': {
      width: '100%'
      // paddingRight: theme.spacing(2),
      // paddingLeft: theme.spacing(2),
      // marginRight: "auto",
      // marginLeft: "auto",
      // maxWidth: 1370
    },
    '.lg-mg-bottom': {
      marginBottom: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginBottom: `${theme.spacing(18)}px !important`
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: `${theme.spacing(16)}px !important`
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: `${theme.spacing(14)}px !important`
      }
    },
    '.lg-mg-top': {
      marginTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginTop: `${theme.spacing(18)}px !important`
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: `${theme.spacing(16)}px !important`
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: `${theme.spacing(14)}px !important`
      }
    },
    '.lg-p-top': {
      minHeight: `${theme.spacing(10)}px !important`,
      paddingTop: `${theme.spacing(1)}px !important`,
      [theme.breakpoints.down('md')]: {
        paddingTop: `${theme.spacing(1)}px !important`
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: `${theme.spacing(1)}px !important`
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: `${theme.spacing(1)}px !important`
      }
    },'.listItemLeftPadding': {
      '@media (max-width:  420px)': {
        paddingLeft: `${theme.spacing(1)}px !important`
      },
      paddingBottom: `${theme.spacing(1.75)}px !important`,
      paddingLeft: `${theme.spacing(4)}px !important`,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: `${theme.spacing(4)}px !important`
      },
      paddingTop: `${theme.spacing(1.75)}px !important`
    },

    '.row': {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2)
    },
    '.speaker-basic-info': {
      width: '100%'
    },
    '.speaker-info-container': {
      // display: "flex",
      // justifyContent: "space-evenly",
    },
    // ".speaker-info-top": { flex: 4 },
    // ".speaker-info-charts": { display: "flex", flexFlow: "row wrap" },
    '.speaker-info-section': {
      marginTop: `${theme.spacing(1)}px !important`
      // width: "100%",
    },
    '.speaker-preview': {
      padding: `${theme.spacing(2)}px !important`
    },
    '.speaker-preview a': {
      color: theme.palette.common.black,
      textDecoration: 'none'
    },
    '.speaker-preview-chart-container': {
      height: '200px',
      marginTop: theme.spacing(2),
      width: '100%'
    },
    '.speaker-preview-wrapper': {
      // boxShadow: "0px 5px 5px -2px rgb(0,0,0,0.5)",
      marginTop: `${theme.spacing(2)}px !important`
    },
    '.speakerList': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    '.text-white': {
      color: theme.palette.common.white
    }
  }
})

function globalStyles() {
  return null
}

export default withStyles(styles, { withTheme: true })(globalStyles)
